export default [
  {
    url: "/doctor-clinic/shop",
    name: "Shop",
    icon: "HomeIcon",
    slug: "shop",
    i18n: "Shop"
  },
  {
    url: "/doctor-clinic/orders",
    name: "Orders",
    slug: "orders",
    i18n: "Orders",
    icon: "ListIcon"
  },
  // {
  //   url: "/doctor-clinic/events",
  //   name: "Events",
  //   icon: "CalendarIcon",
  //   slug: "events",
  //   i18n: "Events"
  // },
  // {
  //   url: "/doctor-clinic/resources",
  //   name: "Resources",
  //   icon: "FolderIcon",
  //   slug: "resources",
  //   i18n: "Resources"
  // },
  {
    url: "/doctor-clinic/settings",
    name: "Settings",
    slug: "settings",
    i18n: "Settings",
    icon: "SettingsIcon"
  }
];
